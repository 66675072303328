import React, { useState } from "react"
import styled from "styled-components"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"
import { GridContainer } from "../../styled/GridContainer"
import SingleAd from "./SingleAd"
import Navigation from "./Navigation"
import LazyLoad from "react-lazyload"

const StyledWrapper = styled.div`
    background-color: var(--dark-blue);
    position: relative;
    overflow: hidden;

    ${breakpoints.sm} {
        margin-top: 150px;
        overflow: visible;
    }
`

const AdBanner = ({ acf: { advertisements, image } }: props) => {
    const [active, setActive] = useState(0)

    return (
        <LazyLoad>
            <StyledWrapper>
                <GridContainer>
                    <SingleAd
                        header={advertisements[active].header}
                        text={advertisements[active].text}
                        image={image}
                    />
                    {advertisements.length > 1 && (
                        <Navigation
                            active={active}
                            setActive={setActive}
                            ads={advertisements}
                        />
                    )}
                </GridContainer>
            </StyledWrapper>
        </LazyLoad>
    )
}

export default AdBanner

interface props {
    acf: {
        advertisements: Array<{
            header: string
            text: string
        }>
        image: gatsbyImage
    }
}
