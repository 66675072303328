import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { gatsbyImage } from "@types"
import { breakpoints } from "@breakpoints"

const StyledOuterWrapper = styled.div`
    height: 200px;
    display: flex;
    align-items: center;

    ${breakpoints.sm} {
        height: 250px;
    }

    ${breakpoints.md} {
        height: 350px;
    }

    ${breakpoints.lg} {
        height: 500px;
        align-items: flex-start;
    }
`

const StyledHeader = styled.h2`
    font-size: var(--xlarge-font);
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;

    ${breakpoints.md} {
        font-size: 60px;
    }

    ${breakpoints.lg} {
        margin-bottom: 30px;
    }
`

const StyledTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 20px 0;
    padding-bottom: 50px;

    ${breakpoints.lg} {
        padding: 60px 0;
    }
`
const StyledText = styled.div`
    font-size: var(--small-font);
    width: 110%;
    overflow: hidden;

    ${breakpoints.md} {
        font-size: var(--medium-font);
    }

    ${breakpoints.lg} {
        font-size: var(--xlarge-font);
        line-height: 1.3;
    }

    strong {
        color: var(--light-blue);
    }
`

const StyledImageWrapper = styled.div`
    position: absolute;
    height: 100%;
    right: -50px;
    top: 0;
    width: 75vw;
    pointer-events: none;
    min-width: 350px;
    max-width: 1080px;

    > .gatsby-image-wrapper {
        height: 100%;
    }

    ${breakpoints.sm} {
        right: 0;
        height: 128%;
        top: -28%;
    }
`

const SingleAd = ({ text, image, header }: props) => {
    return (
        <StyledOuterWrapper>
            <div className="grid grid-cols-2  md:grid-cols-8">
                <StyledTextWrapper className="md:col-span-3 lg:col-span-4">
                    <StyledHeader
                        dangerouslySetInnerHTML={{ __html: header }}
                    />
                    <StyledText dangerouslySetInnerHTML={{ __html: text }} />
                </StyledTextWrapper>
                <StyledImageWrapper className="md:col-span-5 lg:col-span-4">
                    <GatsbyImage
                        alt=""
                        image={image.localFile.childImageSharp.gatsbyImageData}
                    />
                </StyledImageWrapper>
            </div>
        </StyledOuterWrapper>
    )
}

export default SingleAd

interface props {
    header: string
    text: string
    image: gatsbyImage
}
