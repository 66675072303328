import React from "react"
import styled from "styled-components"
import { breakpoints } from "@breakpoints"

const StyledWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 20px;

    ${breakpoints.md} {
        bottom: 40px;
    }
`
const StyledCircle = styled.span<{ active: boolean }>`
    width: 10px;
    height: 10px;
    background-color: ${({ active }) =>
        active ? "var(--light-blue)" : "#5B7995;"};
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;

    ${breakpoints.md} {
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }
`

const Navigation = ({ ads, setActive, active }: props) => {
    return (
        <StyledWrapper>
            {ads?.map((_, index) => (
                <StyledCircle
                    key={index}
                    active={active === index}
                    onClick={() => {
                        setActive(index)
                    }}
                />
            ))}
        </StyledWrapper>
    )
}

export default Navigation

interface props {
    ads: Array<any>
    setActive: (index: number) => void
    active: number
}
